import React, { Component } from 'react'
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { ReactComponent as IMalware } from '../icons/tipper/malware-i.svg';
import { ReactComponent as ILogin } from '../icons/covertdna/login.svg';
import { ReactComponent as IPass } from '../icons/covertdna/pass.svg';
import { ReactComponent as ICookie } from '../icons/covertdna/cookie.svg';
import { ReactComponent as IFingerprint } from '../icons/tipper/fingerprint-i.svg';
import { ReactComponent as IVault } from '../icons/tipper/vault-i.svg';
import { ReactComponent as INotebook } from '../icons/tipper/notebook-i.svg';
import { ReactComponent as IMitre } from '../icons/tipper/mitre-i.svg';
import { ReactComponent as IRefresh } from '../icons/eti_management/refresh.svg'
import { ReactComponent as IFDA } from '../icons/tipper/fda-i.svg';
import { ReactComponent as IAbuseIPDB } from '../icons/tipper/abuseipdb-i.svg';
import { ReactComponent as IVirusTotal } from '../icons/tipper/virustotal-i.svg';
import { ReactComponent as IAttackGroup } from '../icons/tipper/attack-group-i.svg';
import { ReactComponent as ICredential } from '../icons/tipper/credential-i.svg';
import { ReactComponent as IFlag } from '../icons/tipper/rag-flag-i.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ReactComponent as ISubmit } from '../icons/history_log/send.svg';
import {createTheme, ThemeProvider, Tooltip as MuiTooltip, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import {CountryContext} from "react-svg-worldmap";
import MDEditor, {codePreview} from "@uiw/react-md-editor";
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";
import Collapsible from "react-collapsible";
import Dropdown from "react-bootstrap/Dropdown";

const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

function get_patch_severity(data) {
    let severity = 5
    if ("updates" in data) {
        if (data["updates"]["msg"] && data["updates"]["msg"].toLowerCase().startsWith("error")) {
            severity = 6
        } else {
            Object.keys(data["updates"]).forEach(function (key, index) {
                if (isObject(data["updates"][key]) && "severity" in data["updates"][key]) {
                    if (data["updates"][key]["severity"] < severity) {
                        severity = data["updates"][key]["severity"]
                    }
                }
            }, data["updates"]);
        }
    } else {
        severity = 7
    }
    return severity;
}

function check_server(data) {
    return data && "sys_info" in data && "full_name" in data["sys_info"]
        && data["sys_info"]["full_name"].toLowerCase().includes("server")
}

function check_kfire(data) {
    return data && "sys_info" in data && "firewall" in data["sys_info"]
}

function get_reboot_required(data) {
    if ("updates" in data) {
        for (const key of Object.keys(data["updates"])) {
            if (data["updates"][key]["reboot_required"]) {
                return true
            }
        }
    }
    return false
}

function get_patch_severity_render(data, card) {
    let reboot_required = get_reboot_required(data)
    switch (get_patch_severity(data)) {
        case 0:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/critical-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Critical
            </div>))
        case 1:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/important-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Important
            </div>))
        case 2:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/moderate-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Moderate
            </div>))
        case 3:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 24,
                    height: card ? 20 : 24,
                }}/> : ""}
                <img src="/main/low-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Low
            </div>))
        case 4:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                {reboot_required ? <IRefresh style={{
                    width: card ? 20 : 22,
                    height: card ? 20 : 22,
                }}/> : ""}
                <img src="/main/undefined-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Undefined
            </div>))
        case 5:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/updated-i.svg" width={card ? 20 : 24} alt="patch-icon"/>
                Patched
            </div>))
        case 6:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/critical-i.svg" width={card?20:24} alt="patch-icon"/>
                {data["updates"]["msg"]}
            </div>))
        case 7:
            return ((<div style={{display: "flex", gap: 4, alignItems: "center"}}>
                <img src="/main/critical-i.svg" width={card?20:24} alt="patch-icon"/>
                N/A
            </div>))
    }
}

function safe_index(data, key1, key2, key3) {
    if (key3) {
        if (key1 in data && key2 in data[key1] && key3 in data[key1][key2] && data[key1][key2][key3] !== undefined && data[key1][key2][key3] !== null) {
            return data[key1][key2][key3]
        }
    } else if (key2) {
        if (key1 in data && key2 in data[key1] && data[key1][key2] !== undefined && data[key1][key2] !== null) {
            return data[key1][key2]
        }
    } else if (key1 in data && data[key1] !== undefined && data[key1] !== null) {
        return data[key1]
    }
    return "N/A"
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function get_status_color(status, transparent) {
    switch (status) {
        case "New":
            return transparent ? "rgba(244,63,94,0.25)" : "#F43F5E"
        case "Pending":
            return transparent ? "rgba(245,158,11,0.25)" : "#F43F5E"
        case "False Positive":
            return transparent ? "rgba(139,92,246,0.25)" : "#8B5CF6"
        case "Closed":
            return transparent ? "rgba(6,182,212,0.25)" : "#06B6D4"
        default:
            return ""
    }
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

const getStyle = ({
                      countryValue,
                      countryCode,
                      minValue,
                      maxValue,
                      color,
                  }: CountryContext) => ({
    fill: countryValue ? color: "rgb(85,115,85)",
    fillOpacity: 0.5,
    stroke: "white",
    strokeWidth: 1,
    cursor: "pointer",
});


const columns = [
    {
        accessorKey: 'priority',
        header: 'Priority',
        size: 20,
    },
    {
        accessorKey: 'host',
        header: 'Host / User',
        size: 150,
    },
    {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
    },
    {
        accessorKey: 'name',
        header: 'Name / Domain',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {
                    cell.row.original["name"] ?
                        cell.row.original["name"]
                        :
                        cell.row.original["Credentials"] ?
                            cell.row.original["Credentials"][0]["Malware"]
                            :
                            cell.row.original["domain"]
                }
            </div>
        ),
    },
    {
        header: 'Country',
        size: 150,
        Cell: ({cell}) => (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyItems: "center",
                alignItems: "center"
            }}>
                {
                    cell.row.original["priority"].charAt(0) === 'C' || cell.row.original["priority"].charAt(0) === 'B' ?
                        "N/A"
                        :
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyItems: "center",
                            alignItems: "center"
                        }}>
                            <div>
                                {
                                    <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                                }
                            </div>
                            <div>
                                {getISO(cell.row.original["country"])}
                            </div>
                        </div>
                }
            </div>
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({cell}) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    background: cell.getValue() === "Pending" ? get_status_color(cell.getValue(), false) : "transparent",
                    color: cell.getValue() === "Pending" ? "black" : get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue() === "Pending" ? "LIVE IR" : cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'snow_ticket',
        header: 'SNOW Ticket',
        size: 150,
    },
    {
        accessorKey: 'created',
        header: 'First Detected',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
    {
        accessorKey: 'updated',
        header: 'Last Updated',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];


export default class Detections extends Component {

    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });

    constructor(props) {
        super(props);
        this.tipper_box_ref = React.createRef()
        this.state = {
            hic_data: {},
            hic_otp: "",
            username: '',
            token: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0, 0, 0],
            p: [0, 0, 0, 0],
            p_val: [0, 0, 0, 0],
            table_data: [],
            filter_index: 4,
            showModal: false,
            showPlaybook: false,
            modal_data: {},
            playbook: {},
            summary: {},
            tp_rate: 0,
            log_input_text: "",
            show_log_input: false
        };
        this.getTableData = this.getTableData.bind(this);
        this.set_dials = this.set_dials.bind(this);
        this.OpenModal = this.OpenModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.close_hic = this.close_hic.bind(this)
        this.getCountryRender = this.getCountryRender.bind(this);
        this.getBreachCountryRender = this.getBreachCountryRender.bind(this)
        this.update_detection = this.update_detection.bind(this);
        this.getBreachDate = this.getBreachDate.bind(this)
        this.query_device_data = this.query_device_data.bind(this)
        this.get_dropdown_style = this.get_dropdown_style.bind(this)
        this.getBreachURL = this.getBreachURL.bind(this)
        this.getCredColors = this.getCredColors.bind(this)
        this.getIcon = this.getIcon.bind(this)
        this.update_table = this.update_table.bind(this)
        this.get_dropdown_text = this.get_dropdown_text.bind(this)
        this.getLastUser = this.getLastUser.bind(this)
        this.getStatusRender = this.getStatusRender.bind(this)
        this.isolation_route = this.isolation_route.bind(this)
        this.get_auth_to_isolate = this.get_auth_to_isolate.bind(this)
        this.get_auth_to_act = this.get_auth_to_act.bind(this)
    }

    close_hic() {
        this.setState({hic_data: {}, showHIC: false, hic_otp: ""})
    }

    isolation_route() {
        let jsonData = {
            "token": this.state.token,
            "company": this.state.company,
            "host": this.state.hic_data["key"],
            "isolate": this.state.hic_state,
            "otp": parseInt(this.state.hic_otp)
        }
        this.props.post_request("isolation", jsonData).then((response) => {
            if (response) {
                if (response["success"]) {
                    this.close_hic()
                } else {
                    this.setState({invalid_otp: true})
                    setTimeout(function () {
                        this.setState({invalid_otp: false});
                    }.bind(this), 3000);
                }
            } else {
                console.log("failed isolation_route")
            }
        }).catch((err) => {
            console.log("isolation_route", err)
        })
    }

    get_auth_to_isolate(data) {
        let page_data = this.props.page_data()
        if (page_data["cp_data"]["URN"]) {
            let cp_data = page_data["cp_data"]
            if (check_server(data)) {
                return safe_index(cp_data, "IRDeclaration", "Servers").includes("Authority to Isolate")
            } else {
                return safe_index(cp_data, "IRDeclaration", "Endpoints").includes("Authority to Isolate")
            }
        }
        return false
    }

    get_auth_to_act(data) {
        let page_data = this.props.page_data()
        if (page_data["cp_data"]["URN"]) {
            let cp_data = page_data["cp_data"]
            if (check_server(data)) {
                return safe_index(cp_data, "IRDeclaration", "Servers").includes("Authority to Act")
            } else {
                return safe_index(cp_data, "IRDeclaration", "Endpoints").includes("Authority to Act")
            }
        }
        return false
    }
    
    getIcon(status, card) {
        let w = card ? 20 : 28
        let h = card ? 20 : 28
        switch (status) {
            case "Online":
                return (
                    <img src="/main/mgt-online-i.svg" alt="online-i" width={w} height={h}/>
                )
            case "Offline":
                return (
                    <img src="/main/mgt-offline-i.svg" alt="offline-i" width={w} height={h}/>
                )
            case "Stale":
                return (
                    <img src="/main/mgt-stale-i.svg" alt="stale-i" width={w} height={h}/>
                )
            case "Isolated":
                return (
                    <img src="/main/mgt-isolated-i.svg" alt="isolated-i" width={w} height={h}/>
                )
        }
        return (<div />)
    }
    
    getStatusRender(device, card) {
        let state = "Offline"
        if (device["last_seen"] > this.state.minute_ago) {
            state = "Online"
        } else if (device["last_seen"] <= this.state.month_ago) {
            state = "Stale"
        }
        if (device["isolated"]) {
            state = "Isolated"
        }
        return (

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px"
            }}>
                <div style={{display: "flex", justifyContent: "center", gap: "8px"}}>
                    {this.getIcon(state, card)}
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "2px"
                }}>
                    <div>{state}</div>
                    <div hidden={card} style={{
                        fontSize: 14,
                        color: "#A3A3A3"
                    }}>
                        {
                            check_kfire(device) ?
                                "SFire "
                                :
                                "ETI "
                        }
                        v{device["version"]}
                    </div>
                </div>
            </div>
        )
    }
    
    getLastUser(users) {
        if (!users) return ""
        if (users && users.length === 0) return "No User"
        let latest = users[0]
        let active_users = []

        users.forEach((user) => {
            if (user["status"] < 7) {
                active_users.push(user)
            }
        })

        if (active_users.length !== 0) {
            latest = active_users[0]
            active_users.forEach((user) => {
                if (user["last_seen"] > latest["last_seen"]) {
                    latest = user
                }
            })
        } else {
            users.forEach((user) => {
                if (user["last_seen"] > latest["last_seen"]) {
                    latest = user
                }
            })
        }
        return latest["domain"] + "\\" + latest["user"]
    }
    
    get_dropdown_text(state) {
        state = state.toLowerCase()
        switch (state) {
            case "new":
                return "New"
            case "pending":
                return "Live IR"
            case "closed":
                return "Closed (TP)"
            case "false positive":
                return "Closed (FP)"
            default:
                return "N/A"
        }
    }
    
    get_dropdown_style(state) {
        state = state.toLowerCase()
        switch (state) {
            case "new":
                return {
                    borderRight: "1px #F43F5E solid",
                    borderTop: "1px #F43F5E solid",
                    borderBottom: "1px #F43F5E solid",
                    background: "#18181B",
                    color: "white"
                }
            case "pending":
                return {
                    borderRight: "1px #F43F5E solid",
                    borderTop: "1px #F43F5E solid",
                    borderBottom: "1px #F43F5E solid",
                    background: "#F43F5E",
                    color: "black"
                }
            case "closed":
                return {
                    borderRight: "1px #06B6D4 solid",
                    borderTop: "1px #06B6D4 solid",
                    borderBottom: "1px #06B6D4 solid",
                    background: "#18181B",
                    color: "#06B6D4"
                }
            case "false positive":
                return {
                    borderRight: "1px #8B5CF6 solid",
                    borderTop: "1px #8B5CF6 solid",
                    borderBottom: "1px #8B5CF6 solid",
                    background: "#18181B",
                    color: "#8B5CF6"
                }
            default:
                return {}
        }
    }
    
    getCredColors(creds) {
        switch (creds["CredentialType"].length) {
            case 1:
                return "#EAB308"
            case 2:
                return "#F97316"
            case 3:
                return "#F43F5E"
        }
        return "#71717A"
    }

    OpenModal(data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        if (data["host_key"]) {
            this.query_device_data(data)
        } else {
            this.setState({modal_data: data, showModal: true})
        }
    }

    getBreachURL() {
        let url = "N/A"
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["group"]) {
                url = this.state.modal_data["risk"]["post_url"]
            } else url = this.state.modal_data["risk"]["url"]
        }
        let image

        if (url.includes(".onion")) {
            image = "/main/onion-link-i.svg"
            url = url.replaceAll(".", "[.]")
        } else {
            image = "/main/link-i.svg"
        }

        let full_url = url
        if (url.length > 40) {
            url = url.slice(0, 40) + "..."
        }
        return (
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                <img src={image} alt={"url-icon"}/>
                <a href={full_url} target="_blank"
                   rel="noreferrer"
                   style={{
                       color: "#06B6D4"
                   }}
                >
                    {url}
                </a>
            </div>
        )
    }

    getBreachDate() {
        let time = 0
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["risk"]["date"]) {
                time = this.state.modal_data["risk"]["date"]
            } else time = this.state.modal_data["risk"]["published"]
        }
        return (
            <div>
                {get_time_string(time)}
            </div>
        )
    }

    getBreachCountryRender() {
        let code = getISO(this.state.modal_data["country"], true)
        let name = getISO(this.state.modal_data["country"])
        return (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                {name}
            </div>
        )
    }

    CloseModal() {
        this.setState({modal_data: {}, showModal: false, show_log_input: false, log_input_text: ""});
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = this.state.modal_data["country"][1]
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {this.state.modal_data["country"][0]}
                    {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                </div>
            )
        }
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let token = data.token
        let company = data.company;
        if (this.state.company !== data.company) {
            this.setState({username: data.username, token: token, key: key, company: company, keys: data.keys})
        }
        if (token !== '') {
            let jsonData = {
                "company": company,
                "token": token
            }
            if (this.wait_backend) {
                return
            }
            this.wait_backend = true
            this.props.post_request("detections", jsonData).then((response) => {
                if (response) {
                    this.setState({stats: response})
                } else {
                    console.log("failed fetch_detections")
                }
                this.wait_backend = false
            }).catch((err) => {
                console.log("fetch_detections", err)
                this.wait_backend = false
            })
        }

        if (data["detection_data"] && data["detection_data"] !== "" && this.state.stats.detections) {
            this.OpenModal(data["detection_data"])
            this.props.open_detection("")
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("detections", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("detections")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );
        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
    }

    update_table(e) {
        this.setState({filter_index: parseInt(e.currentTarget.id)})
    }

    query_device_data(data) {
        let jsonData = {
            "key": this.state.key,
            "host": data["host_key"]
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/query_device_data", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json["data"]) {
                        data["device_data"] = json["data"]
                        this.setState({modal_data: data, showModal: true, minute_ago: Math.floor(new Date().getTime() / 1000.0) - 60, month_ago: Math.floor(new Date().getTime()/1000.0) - (60 * 43800)})
                    }
                });
            }
        }).catch((err) => {
            console.log("query_device_data", err)
        })
    }

    update_detection(e, log, rfi) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "data": log !== undefined ? log : parseInt(e.currentTarget.id)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + company + (rfi ? "/update_rfi" : "/update_detection"), {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.status) {
                        let modal_data = this.state.modal_data
                        modal_data["status"] = json.status
                        this.setState({modal_data: modal_data})
                    }
                    if (json.log) {
                        let modal_data = this.state.modal_data
                        modal_data["log"] = json.log
                        this.setState({modal_data: modal_data})
                    }
                });
            }
        }).catch((err) => {
            console.log("update_detection", err)
        })
    }

    getTableData() {
        if (this.state.stats.detections) {
            let items = []
            let map_data = []
            let d = [0, 0, 0, 0]
            let tp_rate = 0
            let data = this.state.stats.detections.concat(this.state.stats.risk_detections)
            for (let item of data) {
                items.push(item)
                if (item["status"] === "False Positive") {
                    tp_rate++
                }

                if (item["status"] === "New" || item["status"] === "Pending") {
                    switch (item.priority) {
                        case 'P1':
                            d[0]++
                            break
                        case 'P2':
                            d[1]++
                            break
                        case 'P3':
                            d[2]++
                            break
                        case 'P4':
                            d[3]++
                            break
                    }
                }

                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function (key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            if (!deepEqual(this.state.table_data, items)) {
                this.setState({
                    table_data: items,
                    d: d,
                    map_data: map_data,
                    tp_rate: Math.round((tp_rate / this.state.stats.c2) * 10000) / 100
                })
            }
        }
        if (this.state.stats.c2) {
            this.set_dials()
        }

        switch (this.state.filter_index) {
            case 4:
                return this.state.table_data
            case 0:
                return this.state.table_data.filter((entry) => entry.status === "New");
            case 1:
                return this.state.table_data.filter((entry) => entry.status === "Pending");
            case 2:
                return this.state.table_data.filter((entry) => entry.status === "False Positive");
            case 3:
                return this.state.table_data.filter((entry) => entry.status === "Closed");
            default:
                return this.state.table_data
        }
    }

    set_dials() {
        let p1 = (this.state.stats.p1 / this.state.stats.c2) * 100
        if (p1 !== this.state.p[0]) {
            this.setState({
                p: [
                    p1,
                    (this.state.stats.p2 / this.state.stats.c2) * 100,
                    (this.state.stats.p3 / this.state.stats.c2) * 100,
                    (this.state.stats.p4 / this.state.stats.c2) * 100
                ],
                p_val: [
                    this.state.stats.p1,
                    this.state.stats.p2,
                    this.state.stats.p3,
                    this.state.stats.p4
                ]
            })
        }
    }

    render() {
        return (
            <div
                className="cp-scroll-bar-div"
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Tipper"
                    style={{
                        overlay: {
                            opacity: this.tipper_box_ref.current !== null && this.tipper_box_ref.current.offsetHeight > 10 ? 1 : 0,
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            height: this.tipper_box_ref.current !== null ? this.tipper_box_ref.current.offsetHeight + 48 : "860px",
                            width: "1240px",
                            background: "#171717"
                        },
                    }}
                >
                    {
                        this.state.modal_data["priority"] ?
                            this.state.modal_data["priority"].charAt(0) === "B" ?
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px"
                                }}
                                     ref={this.tipper_box_ref}
                                >
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        lineHeight: "28px",
                                        fontWeight: "600",
                                    }}
                                    >
                                        <div style={{color: "#F9B233"}}>
                                            ShadowVault Detection Tipper
                                        </div>
                                        <button
                                            style={{
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "transparent",
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                marginLeft: "auto"
                                            }}
                                            id="close-modal"
                                            onClick={this.CloseModal}
                                        >
                                            <img
                                                alt="white-close"
                                                src="/main/white-close.svg"
                                            />
                                        </button>
                                    </div>
                                    {/*new*/}

                                    <div style={{
                                        width: "100%",
                                        minHeight: this.state.modal_data["device_data"] ? "180px" : "130px",
                                        borderRadius: 8,
                                        padding: 8,
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#3F3F46",
                                        gap: 8,
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            height: "70px",
                                            position: "relative",
                                            color: 'white',
                                            fontSize: 22,
                                            fontFamily: 'Inter',
                                            display: "flex",
                                            gap: 8,
                                        }}
                                        >
                                            <div style={{
                                                width: '100%',
                                                height: '100%',
                                                paddingLeft: 4,
                                                paddingRight: 8,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 40,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-start',
                                                        gap: 10,
                                                        display: 'flex'
                                                    }}>
                                                        <IVault style={{
                                                            width: "32px",
                                                            height: "32px",
                                                            color: "#D946EF",
                                                        }}/>
                                                    </div>
                                                    <div style={{
                                                        flex: '1 1 0',
                                                        height: 40,
                                                        paddingRight: 4,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>{this.state.modal_data["domain"]}
                                                            </div>
                                                            <div style={{
                                                                color: '#E4E4E7',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>{this.state.modal_data["priority"]} - ShadowVault
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button
                                                    style={{
                                                        fontSize: 12,
                                                        height: 32,
                                                        padding: 8,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "1px solid #06B6D4",
                                                        backgroundColor: "transparent",
                                                        color: "#06B6D4",
                                                        position: "relative"
                                                    }}
                                                    id="playbook"
                                                    onClick={() => this.setState({
                                                        showPlaybook: true,
                                                        playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                    })}
                                                >
                                                    Detection Playbook
                                                </button>

                                                <div style={{
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    gap: 2,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        width: 148,
                                                        height: 32,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            alignSelf: 'stretch',
                                                            padding: 8,
                                                            background: '#18181B',
                                                            borderTopLeftRadius: 4,
                                                            borderBottomLeftRadius: 4,
                                                            borderLeft: '1px #F59E0B solid',
                                                            borderTop: '1px #F59E0B solid',
                                                            borderBottom: '1px #F59E0B solid',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>RAG Status:
                                                            </div>

                                                            <img style={{
                                                                width: 16,
                                                                height: 16,
                                                            }} alt="rag-flag" src="/main/rag-flag-i.svg"/>

                                                            <div style={{
                                                                color: '#F59E0B',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '700',
                                                            }}>AMBER
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <img style={{
                                                        width: 1,
                                                        height: 32,
                                                    }} src="/main/dotted-vertical-small.svg"
                                                         alt={"vertical-small"}/>

                                                    <Dropdown className="Tipper-Dropdown-defaults">
                                                        <Dropdown.Toggle className="Tipper-Dropdown-defaults"
                                                                         style={this.get_dropdown_style(this.state.modal_data["status"])}>
                                                            <div style={{
                                                                width: "100%",
                                                                textAlign: "left",
                                                                display: "flex",
                                                                gap: "8px"
                                                            }}>
                                                                {this.get_dropdown_text(this.state.modal_data["status"])}
                                                            </div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="RFI-Dropdown-menu">
                                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                                           onClick={() => this.update_detection("", 0)}>
                                                                New
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                                           onClick={() => this.update_detection("", 1)}>
                                                                Live IR
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                                           onClick={() => this.update_detection("", 2)}>
                                                                Closed (TP)
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className="RFI-Dropdown-item"
                                                                           onClick={() => this.update_detection("", 3)}>
                                                                Closed (FP)
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '100%',
                                            height: '36px',
                                            paddingLeft: 4,
                                            paddingRight: 8,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{
                                                cursor: "pointer",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 4,
                                                display: 'flex'
                                            }}
                                                 id="Contact"
                                                 onClick={this.props.open_client_playbook}
                                            >
                                                <INotebook style={{
                                                    color: "#F59E0B"
                                                }}/>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>IR Contacts
                                                </div>
                                            </div>
                                            <div style={{
                                                width: "1px",
                                                height: "100%",
                                                border: '1px #A1A1AA solid'
                                            }}/>
                                            {
                                                this.state.modal_data["device_data"] ?
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                         id="netflow"
                                                    >
                                                        <IFDA style={{
                                                            color: "#F59E0B"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>FDA (60x60)
                                                        </div>
                                                    </div> : ""
                                            }


                                            <div style={{
                                                marginLeft: "auto",
                                                cursor: "pointer",
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 4,
                                                display: 'flex'
                                            }}
                                                 onClick={() => openInNewTab("https://www.virustotal.com/gui/domain/" + this.state.modal_data["domain"])}
                                                 id="viustotal"
                                            >
                                                <IVirusTotal style={{
                                                    color: "#3B82F6"
                                                }}/>
                                                <div style={{
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>VirusTotal
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            this.state.modal_data["device_data"] ?
                                                <div style={{display: "flex", flexDirection: "column"}}>
                                                    <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                                         alt="dotted-line"/>

                                                    <div style={{
                                                        padding: 8,
                                                        width: "100%",
                                                        height: "90px",
                                                        position: "relative",
                                                        color: 'white',
                                                        fontSize: 22,
                                                        fontFamily: 'Inter',
                                                        display: "flex",
                                                        gap: 8,
                                                    }}
                                                    >


                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexDirection: "column"
                                                        }}>
                                                            {
                                                                check_kfire(this.state.modal_data["device_data"]) ?
                                                                    <img src="/main/device-kryptofire-i.svg"
                                                                         alt="device-icon" width={48}/>
                                                                    :
                                                                    check_server(this.state.modal_data["device_data"]) ?
                                                                        <img src="/main/device-server-i.svg"
                                                                             alt="device-icon" width={48}/>
                                                                        :
                                                                        <img src="/main/device-workstation-i.svg"
                                                                             alt="device-icon" width={48}/>
                                                            }
                                                            <div style={{fontSize: 12}}>
                                                                {
                                                                    check_kfire(this.state.device_data) ?
                                                                        this.state.modal_data["device_data"]["sys_info"]["firewall"]
                                                                        :
                                                                        check_server(this.state.modal_data["device_data"]) ? "Server" : "Workstation"
                                                                }
                                                            </div>

                                                        </div>

                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            border: '1px #A1A1AA solid'
                                                        }}/>

                                                        <div style={{
                                                            width: '500px',
                                                            height: '100%',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                display: "flex",
                                                                gap: 4,
                                                                alignItems: "center",
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                <button
                                                                    style={{
                                                                        marginLeft: "auto",
                                                                        cursor: "pointer",
                                                                        border: "none",
                                                                        backgroundColor: "transparent",
                                                                        width: "16px",
                                                                        height: "16px"
                                                                    }}
                                                                    onClick={() => this.props.open_device(this.state.modal_data["device_data"]["key"])}
                                                                    id="device_btn"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            flex: "1",
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            height: "16px",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                position: "relative",
                                                                                width: "16px",
                                                                                height: "16px",
                                                                            }}
                                                                            alt=""
                                                                            src="/main/show-i.svg"
                                                                        />
                                                                    </div>
                                                                </button>
                                                                {this.state.modal_data["device_data"]["host"]}
                                                            </div>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {this.state.modal_data["device_data"]["ip"]}
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {this.getLastUser(this.state.modal_data["device_data"]["users"])}
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            border: '1px #A1A1AA solid'
                                                        }}/>

                                                        <div style={{
                                                            width: '900px',
                                                            height: '100%',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {safe_index(this.state.modal_data["device_data"], "sys_info", "full_name")}
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                display: "flex",
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                OS Build&nbsp;
                                                                {safe_index(this.state.modal_data["device_data"], "sys_info", "major")}
                                                                .
                                                                {safe_index(this.state.modal_data["device_data"], "sys_info", "minor")}
                                                                .
                                                                {safe_index(this.state.modal_data["device_data"], "sys_info", "patch")}
                                                                {
                                                                    safe_index(this.state.modal_data["device_data"], "sys_info", "build") === "N/A" ? "" :
                                                                        <div>
                                                                            .
                                                                            {safe_index(this.state.modal_data["device_data"], "sys_info", "build")}
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div style={{
                                                                fontSize: 12,
                                                                display: "flex",
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {
                                                                    get_patch_severity_render(this.state.modal_data["device_data"], true)
                                                                }
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            border: '1px #A1A1AA solid'
                                                        }}/>

                                                        <div style={{
                                                            width: '400px',
                                                            height: '100%',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {this.getStatusRender(this.state.modal_data["device_data"], true)}
                                                            </div>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                Last Seen
                                                            </div>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 12,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '400',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                {get_time_string(this.state.modal_data["device_data"]["last_seen"])}
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            border: '1px #A1A1AA solid'
                                                        }}/>

                                                        <div style={{
                                                            width: '400px',
                                                            height: '100%',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                Authority
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                gap: 8,
                                                                alignItems: "center"
                                                            }}>
                                                                {
                                                                    this.get_auth_to_act(this.state.modal_data["device_data"]) ?
                                                                        (<img src="/main/double-check-i.svg"
                                                                              alt="flag"/>)
                                                                        :
                                                                        (<img src="/main/double-cross-i.svg"
                                                                              alt="flag"/>)
                                                                }
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Act
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                gap: 8,
                                                                alignItems: "center"
                                                            }}>
                                                                {
                                                                    this.get_auth_to_isolate(this.state.modal_data["device_data"]) ?
                                                                        (<img src="/main/double-check-i.svg"
                                                                              alt="flag"/>)
                                                                        :
                                                                        (<img src="/main/double-cross-i.svg"
                                                                              alt="flag"/>)
                                                                }
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Isolate
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            width: '1px',
                                                            height: '100%',
                                                            border: '1px #A1A1AA solid'
                                                        }}/>

                                                        <div style={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex',
                                                            cursor: 'pointer'
                                                        }}
                                                             onClick={() => this.setState({
                                                                 showHIC: true,
                                                                 hic_state: !this.state.modal_data["device_data"]["isolated"],
                                                                 hic_data: this.state.modal_data["device_data"]
                                                             })}
                                                        >
                                                            <div style={{
                                                                height: 32,
                                                                padding: 8,
                                                                background: '#18181B',
                                                                borderRadius: 2,
                                                                border: '1px #F43F5E solid',
                                                                justifyContent: 'center',
                                                                width: 120,
                                                                alignItems: 'center',
                                                                gap: 4,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#F43F5E',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '500',
                                                                }}
                                                                >Isolation Control
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>

                                    {
                                        this.state.modal_data["device_data"] ?
                                            check_kfire(this.state.modal_data["device_data"]) ?
                                                <div style={{
                                                    width: 97,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    background: 'rgba(244, 63, 94, 0.25)',
                                                    borderRadius: 16,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        color: '#F43F5E',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>ShadowFire
                                                    </div>
                                                </div>
                                                :
                                                <div style={{
                                                    width: 112,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    background: 'rgba(6, 182, 212, 0.25)',
                                                    borderRadius: 16,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        color: '#22D3EE',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        ShadowAgent
                                                    </div>
                                                </div>
                                            : <div style={{
                                                width: 152,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                background: 'rgba(6, 182, 212, 0.25)',
                                                borderRadius: 16,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 4,
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    color: '#22D3EE',
                                                    fontSize: 14,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    {this.state.modal_data["host"]}
                                                </div>
                                            </div>
                                    }

                                    {/*new*/}
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px"
                                    }}>
                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white",
                                        }}
                                        >

                                            <div style={{
                                                background: "#3F3F46",
                                                width: "100%",
                                                borderTopRightRadius: 8,
                                                borderTopLeftRadius: 8,
                                                padding: 8,
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "40px",
                                            }}
                                            >
                                                <IVault style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    color: "#65A30D",
                                                }}/>
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Bad Domain Details
                                                </div>
                                            </div>

                                            <div className="inner-tip-box"
                                                 style={{
                                                     borderTopRightRadius: 0,
                                                     borderTopLeftRadius: 0,
                                                     height: "132px",
                                                 }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "4px",
                                                    height: "calc(100%-24px)"
                                                }}
                                                     id="group-data">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "#A1A1AA",
                                                        gap: "4px",
                                                        width: "138px"
                                                    }} id="section1">
                                                        <div>Type:</div>
                                                        <div>Domain:</div>
                                                        <div>Contacted IP:</div>
                                                        <div>Potential Intent:</div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }} id="section2"
                                                    >
                                                        <div>{this.state.modal_data["bdv"]["type"]}</div>
                                                        <div>{this.state.modal_data["domain"]}</div>
                                                        <div>{this.state.modal_data["dest_ip"]}</div>
                                                        <div style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            gap: 4,
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            {this.state.modal_data["bdv"]["tags"].map((tag, i) =>
                                                                <div style={{
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                    gap: 8,
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        paddingLeft: 8,
                                                                        paddingRight: 8,
                                                                        background: 'rgba(161, 161, 170, 0.25)',
                                                                        borderRadius: 16,
                                                                        overflow: 'hidden',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            color: '#A1A1AA',
                                                                            fontSize: 14,
                                                                            fontFamily: 'Inter',
                                                                            fontWeight: '600',
                                                                            letterSpacing: 0.07,
                                                                            wordWrap: 'break-word'
                                                                        }}>{tag}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            alignItems: "stretch",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white"
                                        }}
                                        >
                                            <div style={{
                                                background: "#3F3F46",
                                                width: "100%",
                                                borderTopRightRadius: 8,
                                                borderTopLeftRadius: 8,
                                                padding: 8,
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "40px",
                                            }}
                                            >
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt=""
                                                    src="/main/tiphistory.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Action History Log
                                                </div>

                                                <button
                                                    style={{
                                                        marginLeft: "auto",
                                                        width: 120,
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "none",
                                                        backgroundColor: "rgba(6,182,212,0.25)",
                                                        color: "#06B6D4",
                                                        position: "relative",
                                                    }}
                                                    id="new-update"
                                                    onClick={() => this.setState({show_log_input: true})}
                                                >
                                                    New Update
                                                </button>
                                            </div>
                                            <div className="inner-tip-box" style={{
                                                borderTopRightRadius: 0,
                                                borderTopLeftRadius: 0,
                                                flex: "1 1 auto",
                                                gap: "16px"
                                            }}
                                            >
                                                <div className="scroll-bar-div" style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: "0px 0px 0px 8px",
                                                    gap: "8px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "100%",
                                                    height: "calc(100% - 16px)"
                                                }}
                                                >

                                                    {this.state.show_log_input ?
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: "16px",
                                                                width: "100%",
                                                                height: "230px",
                                                                marginRight: "8px",
                                                            }}
                                                        >
                                                            <MDEditor
                                                                value={this.state.log_input_text}
                                                                visibleDragbar={false}
                                                                hideToolbar={true}
                                                                preview="edit"
                                                                extraCommands={[]}
                                                                onChange={(data) => {
                                                                    this.setState({log_input_text: data})
                                                                }}
                                                                height={200}
                                                            />
                                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                <button onClick={() => {
                                                                    this.update_detection("", this.state.log_input_text)
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    <ISubmit style={{
                                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                    }}/>
                                                                    Submit
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px #06B6D4",
                                                                            color: "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : ""
                                                    }

                                                    {
                                                        this.state.modal_data["log"] ?

                                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginRight: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                     id="history-log-entry"
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <img src="/main/tiphistoryclock-i.svg"
                                                                             alt="tip-clock"/>
                                                                        <div style={{color: "#737373"}}>
                                                                            {get_time_string(item["time"])}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginLeft: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <div style={{
                                                                            background: "#71717a",
                                                                            alignSelf: "stretch",
                                                                            flexShrink: "0",
                                                                            width: "1px"
                                                                        }}/>
                                                                        <MDEditor.Markdown source={item["text"]}
                                                                                           style={{
                                                                                               padding: "16px 16px 16px 16px",
                                                                                               color: "#A1A1AA",
                                                                                               background: "#18181B",
                                                                                               borderRadius: "8px",
                                                                                               whiteSpace: 'pre-wrap',
                                                                                               width: "100%",
                                                                                               height: "100%",
                                                                                               maxHeight: "100%",
                                                                                               overflow: "auto"
                                                                                           }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )
                                                            :
                                                            ""
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginRight: "8px",
                                                        gap: "8px"
                                                    }}
                                                         id="history-log-entry"
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                 alt="tip-clock"/>
                                                            <div style={{color: "#737373"}}>
                                                                {get_time_string(this.state.modal_data["created"])}
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginLeft: "8px",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <div style={{
                                                                background: "#71717a",
                                                                alignSelf: "stretch",
                                                                flexShrink: "0",
                                                                width: "1px"
                                                            }}/>
                                                            <MDEditor.Markdown
                                                                source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                style={{
                                                                    padding: "16px 16px 16px 16px",
                                                                    color: "#A1A1AA",
                                                                    background: "#18181B",
                                                                    borderRadius: "8px",
                                                                    whiteSpace: 'pre-wrap',
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "100%",
                                                                    overflow: "auto"
                                                                }}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                                :
                                this.state.modal_data["priority"].charAt(0) === "S" ?
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}
                                         ref={this.tipper_box_ref}
                                    >
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                        >
                                            <div style={{color: "#F9B233"}}>
                                                {this.state.modal_data["priority"]} Detection Tipper
                                            </div>
                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    border: "none",
                                                    backgroundColor: "transparent",
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    marginLeft: "auto"
                                                }}
                                                id="close-modal"
                                                onClick={this.CloseModal}
                                            >
                                                <img
                                                    alt="white-close"
                                                    src="/main/white-close.svg"
                                                />
                                            </button>
                                        </div>
                                        {/*new*/}

                                        <div style={{
                                            width: "100%",
                                            minHeight: this.state.modal_data["device_data"] ? "180px" : "130px",
                                            borderRadius: 8,
                                            padding: 8,
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#3F3F46",
                                            gap: 8,
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                height: "70px",
                                                position: "relative",
                                                color: 'white',
                                                fontSize: 22,
                                                fontFamily: 'Inter',
                                                display: "flex",
                                                gap: 8,
                                            }}
                                            >
                                                <div style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    paddingLeft: 4,
                                                    paddingRight: 8,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        flex: '1 1 0',
                                                        height: 40,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            justifyContent: 'center',
                                                            alignItems: 'flex-start',
                                                            gap: 10,
                                                            display: 'flex'
                                                        }}>
                                                            <IFingerprint style={{
                                                                width: "32px",
                                                                height: "32px",
                                                                color: "#10B981",
                                                            }}/>
                                                        </div>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            height: 40,
                                                            paddingRight: 4,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>{this.state.modal_data["domain"]}
                                                                </div>
                                                                <div style={{
                                                                    color: '#E4E4E7',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>{this.state.modal_data["priority"]} - {this.state.modal_data["group"] ? this.state.modal_data["group"] : this.state.modal_data["breach_type"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button
                                                        style={{
                                                            fontSize: 12,
                                                            height: 32,
                                                            padding: 8,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "1px solid #06B6D4",
                                                            backgroundColor: "transparent",
                                                            color: "#06B6D4",
                                                            position: "relative"
                                                        }}
                                                        id="playbook"
                                                        onClick={() => this.setState({
                                                            showPlaybook: true,
                                                            playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                        })}
                                                    >
                                                        Detection Playbook
                                                    </button>

                                                    <div style={{
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 2,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            width: 148,
                                                            height: 32,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                alignSelf: 'stretch',
                                                                padding: 8,
                                                                background: '#18181B',
                                                                borderTopLeftRadius: 4,
                                                                borderBottomLeftRadius: 4,
                                                                borderLeft: '1px #F59E0B solid',
                                                                borderTop: '1px #F59E0B solid',
                                                                borderBottom: '1px #F59E0B solid',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>RAG Status:
                                                                </div>

                                                                <img style={{
                                                                    width: 16,
                                                                    height: 16,
                                                                }} alt="rag-flag" src="/main/rag-flag-i.svg"/>

                                                                <div style={{
                                                                    color: '#F59E0B',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '700',
                                                                }}>AMBER
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img style={{
                                                            width: 1,
                                                            height: 32,
                                                        }} src="/main/dotted-vertical-small.svg"
                                                             alt={"vertical-small"}/>

                                                        <Dropdown className="Tipper-Dropdown-defaults">
                                                            <Dropdown.Toggle className="Tipper-Dropdown-defaults"
                                                                             style={this.get_dropdown_style(this.state.modal_data["status"])}>
                                                                <div style={{
                                                                    width: "100%",
                                                                    textAlign: "left",
                                                                    display: "flex",
                                                                    gap: "8px"
                                                                }}>
                                                                    {this.get_dropdown_text(this.state.modal_data["status"])}
                                                                </div>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="RFI-Dropdown-menu">
                                                                <Dropdown.Item className="RFI-Dropdown-item"
                                                                               onClick={() => this.update_detection("", 0)}>
                                                                    New
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="RFI-Dropdown-item"
                                                                               onClick={() => this.update_detection("", 1)}>
                                                                    Live IR
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="RFI-Dropdown-item"
                                                                               onClick={() => this.update_detection("", 2)}>
                                                                    Closed (TP)
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="RFI-Dropdown-item"
                                                                               onClick={() => this.update_detection("", 3)}>
                                                                    Closed (FP)
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{
                                                width: '100%',
                                                height: '36px',
                                                paddingLeft: 4,
                                                paddingRight: 8,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    cursor: "pointer",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}
                                                     id="Contact"
                                                     onClick={this.props.open_client_playbook}
                                                >
                                                    <INotebook style={{
                                                        color: "#F59E0B"
                                                    }}/>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>IR Contacts
                                                    </div>
                                                </div>
                                                <div style={{
                                                    width: "1px",
                                                    height: "100%",
                                                    border: '1px #A1A1AA solid'
                                                }}/>
                                                {
                                                    this.state.modal_data["device_data"] ?
                                                        <div style={{
                                                            cursor: "pointer",
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                            display: 'flex'
                                                        }}
                                                             onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                             id="netflow"
                                                        >
                                                            <IFDA style={{
                                                                color: "#F59E0B"
                                                            }}/>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 16,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>FDA (60x60)
                                                            </div>
                                                        </div> : ""
                                                }


                                                <div style={{
                                                    marginLeft: "auto",
                                                    cursor: "pointer",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}
                                                     onClick={() => openInNewTab("https://www.virustotal.com/gui/domain/" + this.state.modal_data["domain"])}
                                                     id="viustotal"
                                                >
                                                    <IVirusTotal style={{
                                                        color: "#3B82F6"
                                                    }}/>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>VirusTotal
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                this.state.modal_data["device_data"] ?
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                                             alt="dotted-line"/>

                                                        <div style={{
                                                            padding: 8,
                                                            width: "100%",
                                                            height: "90px",
                                                            position: "relative",
                                                            color: 'white',
                                                            fontSize: 22,
                                                            fontFamily: 'Inter',
                                                            display: "flex",
                                                            gap: 8,
                                                        }}
                                                        >


                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column"
                                                            }}>
                                                                {
                                                                    check_kfire(this.state.modal_data["device_data"]) ?
                                                                        <img src="/main/device-kryptofire-i.svg"
                                                                             alt="device-icon" width={48}/>
                                                                        :
                                                                        check_server(this.state.modal_data["device_data"]) ?
                                                                            <img src="/main/device-server-i.svg"
                                                                                 alt="device-icon" width={48}/>
                                                                            :
                                                                            <img src="/main/device-workstation-i.svg"
                                                                                 alt="device-icon" width={48}/>
                                                                }
                                                                <div style={{fontSize: 12}}>
                                                                    {
                                                                        check_kfire(this.state.device_data) ?
                                                                            this.state.modal_data["device_data"]["sys_info"]["firewall"]
                                                                            :
                                                                            check_server(this.state.modal_data["device_data"]) ? "Server" : "Workstation"
                                                                    }
                                                                </div>

                                                            </div>

                                                            <div style={{
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>

                                                            <div style={{
                                                                width: '500px',
                                                                height: '100%',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    display: "flex",
                                                                    gap: 4,
                                                                    alignItems: "center",
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    <button
                                                                        style={{
                                                                            marginLeft: "auto",
                                                                            cursor: "pointer",
                                                                            border: "none",
                                                                            backgroundColor: "transparent",
                                                                            width: "16px",
                                                                            height: "16px"
                                                                        }}
                                                                        onClick={() => this.props.open_device(this.state.modal_data["device_data"]["key"])}
                                                                        id="device_btn"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                flex: "1",
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                height: "16px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    position: "relative",
                                                                                    width: "16px",
                                                                                    height: "16px",
                                                                                }}
                                                                                alt=""
                                                                                src="/main/show-i.svg"
                                                                            />
                                                                        </div>
                                                                    </button>
                                                                    {this.state.modal_data["device_data"]["host"]}
                                                                </div>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {this.state.modal_data["device_data"]["ip"]}
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {this.getLastUser(this.state.modal_data["device_data"]["users"])}
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>

                                                            <div style={{
                                                                width: '900px',
                                                                height: '100%',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {safe_index(this.state.modal_data["device_data"], "sys_info", "full_name")}
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    display: "flex",
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    OS Build&nbsp;
                                                                    {safe_index(this.state.modal_data["device_data"], "sys_info", "major")}
                                                                    .
                                                                    {safe_index(this.state.modal_data["device_data"], "sys_info", "minor")}
                                                                    .
                                                                    {safe_index(this.state.modal_data["device_data"], "sys_info", "patch")}
                                                                    {
                                                                        safe_index(this.state.modal_data["device_data"], "sys_info", "build") === "N/A" ? "" :
                                                                            <div>
                                                                                .
                                                                                {safe_index(this.state.modal_data["device_data"], "sys_info", "build")}
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div style={{
                                                                    fontSize: 12,
                                                                    display: "flex",
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {
                                                                        get_patch_severity_render(this.state.modal_data["device_data"], true)
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>

                                                            <div style={{
                                                                width: '400px',
                                                                height: '100%',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {this.getStatusRender(this.state.modal_data["device_data"], true)}
                                                                </div>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Last Seen
                                                                </div>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    {get_time_string(this.state.modal_data["device_data"]["last_seen"])}
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>

                                                            <div style={{
                                                                width: '400px',
                                                                height: '100%',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 16,
                                                                    fontFamily: 'Inter',
                                                                    fontWeight: '600',
                                                                    wordWrap: 'break-word'
                                                                }}>
                                                                    Authority
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    alignItems: "center"
                                                                }}>
                                                                    {
                                                                        this.get_auth_to_act(this.state.modal_data["device_data"]) ?
                                                                            (<img src="/main/double-check-i.svg"
                                                                                  alt="flag"/>)
                                                                            :
                                                                            (<img src="/main/double-cross-i.svg"
                                                                                  alt="flag"/>)
                                                                    }
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        Act
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    alignItems: "center"
                                                                }}>
                                                                    {
                                                                        this.get_auth_to_isolate(this.state.modal_data["device_data"]) ?
                                                                            (<img src="/main/double-check-i.svg"
                                                                                  alt="flag"/>)
                                                                            :
                                                                            (<img src="/main/double-cross-i.svg"
                                                                                  alt="flag"/>)
                                                                    }
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        Isolate
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                width: '1px',
                                                                height: '100%',
                                                                border: '1px #A1A1AA solid'
                                                            }}/>

                                                            <div style={{
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'inline-flex',
                                                                cursor: 'pointer'
                                                            }}
                                                                 onClick={() => this.setState({
                                                                     showHIC: true,
                                                                     hic_state: !this.state.modal_data["device_data"]["isolated"],
                                                                     hic_data: this.state.modal_data["device_data"]
                                                                 })}
                                                            >
                                                                <div style={{
                                                                    height: 32,
                                                                    padding: 8,
                                                                    background: '#18181B',
                                                                    borderRadius: 2,
                                                                    border: '1px #F43F5E solid',
                                                                    justifyContent: 'center',
                                                                    width: 120,
                                                                    alignItems: 'center',
                                                                    gap: 4,
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: '#F43F5E',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '500',
                                                                    }}
                                                                    >Isolation Control
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                        </div>

                                        {
                                            this.state.modal_data["device_data"] ?
                                                check_kfire(this.state.modal_data["device_data"]) ?
                                                    <div style={{
                                                        width: 97,
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        background: 'rgba(244, 63, 94, 0.25)',
                                                        borderRadius: 16,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#F43F5E',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>ShadowFire
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        width: 112,
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        background: 'rgba(6, 182, 212, 0.25)',
                                                        borderRadius: 16,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            color: '#22D3EE',
                                                            fontSize: 14,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            ShadowAgent
                                                        </div>
                                                    </div>
                                                : <div style={{
                                                    width: 152,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    background: 'rgba(6, 182, 212, 0.25)',
                                                    borderRadius: 16,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        color: '#22D3EE',
                                                        fontSize: 14,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        wordWrap: 'break-word'
                                                    }}>
                                                        {this.state.modal_data["host"]}
                                                    </div>
                                                </div>
                                        }

                                        {/*new*/}
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px"
                                        }}>
                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                            }}
                                            >

                                                <div style={{
                                                    background: "#3F3F46",
                                                    width: "100%",
                                                    borderTopRightRadius: 8,
                                                    borderTopLeftRadius: 8,
                                                    padding: 8,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "40px",
                                                }}
                                                >
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/breach-details-i.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Breach Details
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                     style={{
                                                         borderTopRightRadius: 0,
                                                         borderTopLeftRadius: 0,
                                                         height: "242px",
                                                     }}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="group-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "138px"
                                                        }} id="section1">
                                                            <div>Supplier Domain:</div>
                                                            <div>Contacted IP:</div>
                                                            <div>Country:</div>
                                                            <div>Breach Date:</div>
                                                            <div>Breach Type:</div>
                                                            <div>Threat Group:</div>
                                                            <div>Source:</div>
                                                            <div>Severity:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2"
                                                        >
                                                            <div>{this.state.modal_data["domain"]}</div>
                                                            <div>{this.state.modal_data["dest_ip"]}</div>
                                                            {this.getBreachCountryRender()}
                                                            {this.getBreachDate()}
                                                            <div>{this.state.modal_data["breach_type"]}</div>
                                                            <div>{this.state.modal_data["group"] ? this.state.modal_data["group"] : "N/A"}</div>
                                                            {this.getBreachURL()}
                                                            <div>{this.state.modal_data["severity"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                alignItems: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white"
                                            }}
                                            >
                                                <div style={{
                                                    background: "#3F3F46",
                                                    width: "100%",
                                                    borderTopRightRadius: 8,
                                                    borderTopLeftRadius: 8,
                                                    padding: 8,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "40px",
                                                }}
                                                >
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/tiphistory.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Action History Log
                                                    </div>

                                                    <button
                                                        style={{
                                                            marginLeft: "auto",
                                                            width: 120,
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "none",
                                                            backgroundColor: "rgba(6,182,212,0.25)",
                                                            color: "#06B6D4",
                                                            position: "relative",
                                                        }}
                                                        id="new-update"
                                                        onClick={() => this.setState({show_log_input: true})}
                                                    >
                                                        New Update
                                                    </button>
                                                </div>
                                                <div className="inner-tip-box" style={{
                                                    borderTopRightRadius: 0,
                                                    borderTopLeftRadius: 0,
                                                    flex: "1 1 auto",
                                                    gap: "16px"
                                                }}
                                                >
                                                    <div className="scroll-bar-div" style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 0px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "calc(100% - 16px)"
                                                    }}
                                                    >

                                                        {this.state.show_log_input ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "16px",
                                                                    width: "100%",
                                                                    height: "230px",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <MDEditor
                                                                    value={this.state.log_input_text}
                                                                    visibleDragbar={false}
                                                                    hideToolbar={true}
                                                                    preview="edit"
                                                                    extraCommands={[]}
                                                                    onChange={(data) => {
                                                                        this.setState({log_input_text: data})
                                                                    }}
                                                                    height={200}
                                                                />
                                                                <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                    <button onClick={() => {
                                                                        this.update_detection("", this.state.log_input_text)
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        <ISubmit style={{
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        }}/>
                                                                        Submit
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px #06B6D4",
                                                                                color: "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }

                                                        {
                                                            this.state.modal_data["log"] ?

                                                                this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="history-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                                 alt="tip-clock"/>
                                                                            <div style={{color: "#737373"}}>
                                                                                {get_time_string(item["time"])}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <MDEditor.Markdown source={item["text"]}
                                                                                               style={{
                                                                                                   padding: "16px 16px 16px 16px",
                                                                                                   color: "#A1A1AA",
                                                                                                   background: "#18181B",
                                                                                                   borderRadius: "8px",
                                                                                                   whiteSpace: 'pre-wrap',
                                                                                                   width: "100%",
                                                                                                   height: "100%",
                                                                                                   maxHeight: "100%",
                                                                                                   overflow: "auto"
                                                                                               }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg"
                                                                     alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(this.state.modal_data["created"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown
                                                                    source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                    style={{
                                                                        padding: "16px 16px 16px 16px",
                                                                        color: "#A1A1AA",
                                                                        background: "#18181B",
                                                                        borderRadius: "8px",
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100%",
                                                                        overflow: "auto"
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    :
                                    this.state.modal_data["priority"].charAt(0) === "C" ?
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px"
                                        }}
                                             ref={this.tipper_box_ref}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}
                                            >
                                                <div style={{color: "#F9B233"}}>
                                                    ShadowBreach Detection Tipper
                                                </div>
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="close-modal"
                                                    onClick={this.CloseModal}
                                                >
                                                    <img
                                                        alt="white-close"
                                                        src="/main/white-close.svg"
                                                    />
                                                </button>
                                            </div>
                                            {/*new*/}

                                            <div style={{
                                                width: "100%",
                                                minHeight: this.state.modal_data["device_data"] ? "180px" : "130px",
                                                borderRadius: 8,
                                                padding: 8,
                                                display: "flex",
                                                flexDirection: "column",
                                                background: "#3F3F46",
                                                gap: 8,
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    height: "70px",
                                                    position: "relative",
                                                    color: 'white',
                                                    fontSize: 22,
                                                    fontFamily: 'Inter',
                                                    display: "flex",
                                                    gap: 8,
                                                }}
                                                >
                                                    <div style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        paddingLeft: 4,
                                                        paddingRight: 8,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            height: 40,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                gap: 10,
                                                                display: 'flex'
                                                            }}>
                                                                <ICredential style={{
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    color: "#A855F7",
                                                                }}/>
                                                            </div>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 40,
                                                                paddingRight: 4,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.company}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#E4E4E7',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.modal_data["priority"]} - ShadowVault
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button
                                                            style={{
                                                                fontSize: 12,
                                                                height: 32,
                                                                padding: 8,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Detection Playbook
                                                        </button>

                                                        <div style={{
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            gap: 2,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                width: 148,
                                                                height: 32,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    alignSelf: 'stretch',
                                                                    padding: 8,
                                                                    background: '#18181B',
                                                                    borderTopLeftRadius: 4,
                                                                    borderBottomLeftRadius: 4,
                                                                    borderLeft: '1px #F43F5E solid',
                                                                    borderTop: '1px #F43F5E solid',
                                                                    borderBottom: '1px #F43F5E solid',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>RAG Status:
                                                                    </div>

                                                                    <IFlag style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        color: "#F43F5E",
                                                                    }}/>

                                                                    <div style={{
                                                                        color: '#F43F5E',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '700',
                                                                    }}>RED
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img style={{
                                                                width: 1,
                                                                height: 32,
                                                            }} src="/main/dotted-vertical-small.svg"
                                                                 alt={"vertical-small"}/>

                                                            <Dropdown className="Tipper-Dropdown-defaults">
                                                                <Dropdown.Toggle className="Tipper-Dropdown-defaults"
                                                                                 style={this.get_dropdown_style(this.state.modal_data["status"])}>
                                                                    <div style={{
                                                                        width: "100%",
                                                                        textAlign: "left",
                                                                        display: "flex",
                                                                        gap: "8px"
                                                                    }}>
                                                                        {this.get_dropdown_text(this.state.modal_data["status"])}
                                                                    </div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="RFI-Dropdown-menu">
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 0)}>
                                                                        New
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 1)}>
                                                                        Live IR
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 2)}>
                                                                        Closed (TP)
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 3)}>
                                                                        Closed (FP)
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    width: '100%',
                                                    height: '36px',
                                                    paddingLeft: 4,
                                                    paddingRight: 8,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         id="Contact"
                                                         onClick={this.props.open_client_playbook}
                                                    >
                                                        <INotebook style={{
                                                            color: "#F59E0B"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>IR Contacts
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "1px",
                                                        height: "100%",
                                                        border: '1px #A1A1AA solid'
                                                    }}/>
                                                </div>
                                            </div>

                                            <div style={{
                                                width: 102,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                background: 'rgba(6, 182, 212, 0.25)',
                                                borderRadius: 16,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 4,
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    color: '#22D3EE',
                                                    fontSize: 14,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}>
                                                    ShadowLens
                                                </div>
                                            </div>

                                            {/*new*/}
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px"
                                            }}>
                                                <div style={{
                                                    width: "calc(50% - 8px)",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    color: "white",
                                                }}
                                                >

                                                    <div style={{
                                                        background: "#3F3F46",
                                                        width: "100%",
                                                        borderTopRightRadius: 8,
                                                        borderTopLeftRadius: 8,
                                                        padding: 8,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Compromised Credentials Log
                                                        </div>
                                                    </div>

                                                    <div className="inner-tip-box"
                                                         style={{
                                                             borderTopRightRadius: 0,
                                                             borderTopLeftRadius: 0,
                                                             height: "100%",
                                                         }}
                                                    >
                                                        <div className="scroll-bar-div" style={{
                                                            marginTop: "12px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: "0px 0px 16px 8px",
                                                            gap: "8px",
                                                            overflowY: "scroll",
                                                            overflowX: "hidden",
                                                            width: "100%",
                                                            height: "472px"
                                                        }}
                                                        >
                                                            {
                                                                this.state.modal_data["Credentials"].map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="credentials-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tipcred-i.svg"
                                                                                 alt="tip-cred"/>
                                                                            <div style={{
                                                                                width: "100%",
                                                                                alignItems: "center",
                                                                                display: "flex",
                                                                                gap: "16px",
                                                                            }}>
                                                                                <div>Credential
                                                                                    #{(i + 1).toString().padStart(4, '0')}</div>
                                                                                <div style={{
                                                                                    marginLeft: "auto",
                                                                                    display: "flex",
                                                                                    gap: "8px"
                                                                                }}
                                                                                >
                                                                                    <MuiTooltip
                                                                                        title={this.props.tooltip_info("ILogin")}
                                                                                        arrow>
                                                                                        <ILogin style={{
                                                                                            color: item["CredentialType"].includes("Login") ? this.getCredColors(item) : "#71717A",
                                                                                        }}/>
                                                                                    </MuiTooltip>
                                                                                    <MuiTooltip
                                                                                        title={this.props.tooltip_info("IPass")}
                                                                                        arrow>
                                                                                        <IPass style={{
                                                                                            color: item["CredentialType"].includes("Password") ? this.getCredColors(item) : "#71717A"
                                                                                        }}/>
                                                                                    </MuiTooltip>
                                                                                    <MuiTooltip
                                                                                        title={this.props.tooltip_info("ICookie")}
                                                                                        arrow>
                                                                                        <ICookie style={{
                                                                                            color: item["CredentialType"].includes("Cookie") ? this.getCredColors(item) : "#71717A"
                                                                                        }}/>
                                                                                    </MuiTooltip>
                                                                                </div>
                                                                                <div style={{
                                                                                    fontWeight: "bold"
                                                                                }}>{item["Price"]}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <div style={{
                                                                                borderRadius: "8px",
                                                                                padding: "16px 16px 16px 16px",
                                                                                whiteSpace: 'pre-wrap',
                                                                                background: "#18181B",
                                                                                width: "100%"
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                }}
                                                                                     id="malware-data">
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        color: "#A1A1AA",
                                                                                        gap: "4px",
                                                                                        width: "108px"
                                                                                    }} id="section1">
                                                                                        <div>Auth ID:</div>
                                                                                        <div>&nbsp;</div>
                                                                                        <div>Date for Sale:</div>
                                                                                        <div>Domain:</div>
                                                                                        <div>ISP:</div>
                                                                                        <div>Malware:</div>
                                                                                        <div>Vendor:</div>
                                                                                        <div>Device Type:</div>
                                                                                    </div>

                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        gap: "4px"
                                                                                    }} id="section2">
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            gap: 4
                                                                                        }}>
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    navigator.clipboard.writeText(item["Id"]).then({})
                                                                                                }}
                                                                                                style={{
                                                                                                    backgroundColor: "transparent",
                                                                                                    display: "flex",
                                                                                                    alignItems: "center",
                                                                                                    justifyContent: "center",
                                                                                                    cursor: "pointer",
                                                                                                    width: 24,
                                                                                                    height: 24,
                                                                                                    border: "none",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src="/main/tipcopy-i.svg"
                                                                                                    alt="copy"/>
                                                                                            </button>
                                                                                            <div style={{
                                                                                                paddingLeft: 4,
                                                                                                paddingRight: 4,
                                                                                                height: 24,
                                                                                                background: '#27272A',
                                                                                                borderRadius: 4,
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                                display: 'inline-flex'
                                                                                            }}
                                                                                            >
                                                                                                <div style={{
                                                                                                    color: '#F59E0B',
                                                                                                    fontSize: 14,
                                                                                                    fontWeight: '600',
                                                                                                    wordWrap: 'break-word'
                                                                                                }}>
                                                                                                    {item["Id"]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>&nbsp;</div>
                                                                                        <div>{item["DateForSale"]}</div>
                                                                                        <div>{item["Domain"]}</div>
                                                                                        <div>{item["ISP"]}</div>
                                                                                        <div>{item["Malware"]}</div>
                                                                                        <div>{item["CriminalVendor"]}</div>
                                                                                        <div>{item["DeviceType"]}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div style={{
                                                    width: "calc(50% - 8px)",
                                                    alignItems: "stretch",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    color: "white"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#3F3F46",
                                                        width: "100%",
                                                        borderTopRightRadius: 8,
                                                        borderTopLeftRadius: 8,
                                                        padding: 8,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                marginLeft: "auto",
                                                                width: 120,
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>
                                                    <div className="inner-tip-box" style={{
                                                        borderTopRightRadius: 0,
                                                        borderTopLeftRadius: 0,
                                                        flex: "1 1 auto",
                                                        gap: "16px"
                                                    }}
                                                    >
                                                        <div className="scroll-bar-div" style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: "0px 0px 0px 8px",
                                                            gap: "8px",
                                                            overflowY: "scroll",
                                                            overflowX: "hidden",
                                                            width: "100%",
                                                            height: "calc(100% - 16px)"
                                                        }}
                                                        >

                                                            {this.state.show_log_input ?
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginTop: "16px",
                                                                        width: "100%",
                                                                        height: "230px",
                                                                        marginRight: "8px",
                                                                    }}
                                                                >
                                                                    <MDEditor
                                                                        value={this.state.log_input_text}
                                                                        visibleDragbar={false}
                                                                        hideToolbar={true}
                                                                        preview="edit"
                                                                        extraCommands={[]}
                                                                        onChange={(data) => {
                                                                            this.setState({log_input_text: data})
                                                                        }}
                                                                        height={200}
                                                                    />
                                                                    <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                        <button onClick={() => {
                                                                            this.update_detection("", this.state.log_input_text)
                                                                            this.setState({
                                                                                show_log_input: false,
                                                                                log_input_text: ""
                                                                            })
                                                                        }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    borderRadius: "2px",
                                                                                    background: "transparent",
                                                                                    border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                    marginRight: "8px"
                                                                                }}
                                                                        >
                                                                            <ISubmit style={{
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                            }}/>
                                                                            Submit
                                                                        </button>
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                show_log_input: false,
                                                                                log_input_text: ""
                                                                            })
                                                                        }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    borderRadius: "2px",
                                                                                    background: "transparent",
                                                                                    border: "solid 1px #06B6D4",
                                                                                    color: "#06B6D4",
                                                                                    marginRight: "8px"
                                                                                }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            }

                                                            {
                                                                this.state.modal_data["log"] ?

                                                                    this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            marginRight: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                             id="history-log-entry"
                                                                        >
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <img src="/main/tiphistoryclock-i.svg"
                                                                                     alt="tip-clock"/>
                                                                                <div style={{color: "#737373"}}>
                                                                                    {get_time_string(item["time"])}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                marginLeft: "8px",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <div style={{
                                                                                    background: "#71717a",
                                                                                    alignSelf: "stretch",
                                                                                    flexShrink: "0",
                                                                                    width: "1px"
                                                                                }}/>
                                                                                <MDEditor.Markdown source={item["text"]}
                                                                                                   style={{
                                                                                                       padding: "16px 16px 16px 16px",
                                                                                                       color: "#A1A1AA",
                                                                                                       background: "#18181B",
                                                                                                       borderRadius: "8px",
                                                                                                       whiteSpace: 'pre-wrap',
                                                                                                       width: "100%",
                                                                                                       height: "100%",
                                                                                                       maxHeight: "100%",
                                                                                                       overflow: "auto"
                                                                                                   }}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                    :
                                                                    ""
                                                            }

                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginRight: "8px",
                                                                gap: "8px"
                                                            }}
                                                                 id="history-log-entry"
                                                            >
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <img src="/main/tiphistoryclock-i.svg"
                                                                         alt="tip-clock"/>
                                                                    <div style={{color: "#737373"}}>
                                                                        {get_time_string(this.state.modal_data["created"])}
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    marginLeft: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        background: "#71717a",
                                                                        alignSelf: "stretch",
                                                                        flexShrink: "0",
                                                                        width: "1px"
                                                                    }}/>
                                                                    <MDEditor.Markdown
                                                                        source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                        style={{
                                                                            padding: "16px 16px 16px 16px",
                                                                            color: "#A1A1AA",
                                                                            background: "#18181B",
                                                                            borderRadius: "8px",
                                                                            whiteSpace: 'pre-wrap',
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            maxHeight: "100%",
                                                                            overflow: "auto"
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "8px"
                                        }}
                                             ref={this.tipper_box_ref}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}
                                            >
                                                <div style={{color: "#F9B233"}}>
                                                    {this.state.modal_data["priority"]} Detection Tipper
                                                </div>
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="close-modal"
                                                    onClick={this.CloseModal}
                                                >
                                                    <img
                                                        alt="white-close"
                                                        src="/main/white-close.svg"
                                                    />
                                                </button>
                                            </div>
                                            {/*new*/}

                                            <div style={{
                                                width: "100%",
                                                minHeight: this.state.modal_data["device_data"] ? "180px" : "130px",
                                                borderRadius: 8,
                                                padding: 8,
                                                display: "flex",
                                                flexDirection: "column",
                                                background: "#3F3F46",
                                                gap: 8,
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    height: "70px",
                                                    position: "relative",
                                                    color: 'white',
                                                    fontSize: 22,
                                                    fontFamily: 'Inter',
                                                    display: "flex",
                                                    gap: 8,
                                                }}
                                                >
                                                    <div style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        paddingLeft: 4,
                                                        paddingRight: 8,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            flex: '1 1 0',
                                                            height: 40,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                justifyContent: 'center',
                                                                alignItems: 'flex-start',
                                                                gap: 10,
                                                                display: 'flex'
                                                            }}>
                                                                <IMalware style={{
                                                                    width: "32px",
                                                                    height: "32px",
                                                                    color: "#F43F5E",
                                                                }}/>
                                                            </div>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 40,
                                                                paddingRight: 4,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.modal_data["name"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#E4E4E7',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>{this.state.modal_data["priority"]} - {this.state.modal_data["group"]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button
                                                            style={{
                                                                fontSize: 12,
                                                                height: 32,
                                                                padding: 8,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Detection Playbook
                                                        </button>

                                                        <div style={{
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'flex-start',
                                                            gap: 2,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                width: 148,
                                                                height: 32,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    flex: '1 1 0',
                                                                    alignSelf: 'stretch',
                                                                    padding: 8,
                                                                    background: '#18181B',
                                                                    borderTopLeftRadius: 4,
                                                                    borderBottomLeftRadius: 4,
                                                                    borderLeft: '1px #F59E0B solid',
                                                                    borderTop: '1px #F59E0B solid',
                                                                    borderBottom: '1px #F59E0B solid',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>RAG Status:
                                                                    </div>

                                                                    <img style={{
                                                                        width: 16,
                                                                        height: 16,
                                                                    }} alt="rag-flag" src="/main/rag-flag-i.svg"/>

                                                                    <div style={{
                                                                        color: '#F59E0B',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '700',
                                                                    }}>AMBER
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img style={{
                                                                width: 1,
                                                                height: 32,
                                                            }} src="/main/dotted-vertical-small.svg"
                                                                 alt={"vertical-small"}/>

                                                            <Dropdown className="Tipper-Dropdown-defaults">
                                                                <Dropdown.Toggle className="Tipper-Dropdown-defaults"
                                                                                 style={this.get_dropdown_style(this.state.modal_data["status"])}>
                                                                    <div style={{
                                                                        width: "100%",
                                                                        textAlign: "left",
                                                                        display: "flex",
                                                                        gap: "8px"
                                                                    }}>
                                                                        {this.get_dropdown_text(this.state.modal_data["status"])}
                                                                    </div>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu className="RFI-Dropdown-menu">
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 0)}>
                                                                        New
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 1)}>
                                                                        Live IR
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 2)}>
                                                                        Closed (TP)
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="RFI-Dropdown-item"
                                                                                   onClick={() => this.update_detection("", 3)}>
                                                                        Closed (FP)
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{
                                                    width: '100%',
                                                    height: '36px',
                                                    paddingLeft: 4,
                                                    paddingRight: 8,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         id="Contact"
                                                         onClick={this.props.open_client_playbook}
                                                    >
                                                        <INotebook style={{
                                                            color: "#F59E0B"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>IR Contacts
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "1px",
                                                        height: "100%",
                                                        border: '1px #A1A1AA solid'
                                                    }}/>
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         onClick={() => this.props.open_mitre(this.state.modal_data["name"])}
                                                         id="mitre-btn"
                                                    >
                                                        <IMitre style={{
                                                            color: "#F59E0B"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>ATT&CK
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "1px",
                                                        height: "100%",
                                                        border: '1px #A1A1AA solid'
                                                    }}/>
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                         id="netflow"
                                                    >
                                                        <IFDA style={{
                                                            color: "#F59E0B"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>FDA (60x60)
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        marginLeft: "auto",
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         onClick={() => openInNewTab("https://www.abuseipdb.com/check/" + this.state.modal_data["ioa"])}
                                                         id="abuseip"
                                                    >
                                                        <IAbuseIPDB style={{
                                                            color: "#F87171"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>AbuseIPDB
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "1px",
                                                        height: "100%",
                                                        border: '1px #A1A1AA solid'
                                                    }}/>
                                                    <div style={{
                                                        cursor: "pointer",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                         onClick={() => openInNewTab("https://www.virustotal.com/gui/ip-address/" + this.state.modal_data["ioa"])}
                                                         id="viustotal"
                                                    >
                                                        <IVirusTotal style={{
                                                            color: "#3B82F6"
                                                        }}/>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontFamily: 'Inter',
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>VirusTotal
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.modal_data["device_data"] ?
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <img src="/main/netflow-dotted-long.svg" width={"100%"}
                                                                 alt="dotted-line"/>

                                                            <div style={{
                                                                padding: 8,
                                                                width: "100%",
                                                                height: "90px",
                                                                position: "relative",
                                                                color: 'white',
                                                                fontSize: 22,
                                                                fontFamily: 'Inter',
                                                                display: "flex",
                                                                gap: 8,
                                                            }}
                                                            >


                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    flexDirection: "column"
                                                                }}>
                                                                    {
                                                                        check_kfire(this.state.modal_data["device_data"]) ?
                                                                            <img src="/main/device-kryptofire-i.svg"
                                                                                 alt="device-icon" width={48}/>
                                                                            :
                                                                            check_server(this.state.modal_data["device_data"]) ?
                                                                                <img src="/main/device-server-i.svg"
                                                                                     alt="device-icon" width={48}/>
                                                                                :
                                                                                <img
                                                                                    src="/main/device-workstation-i.svg"
                                                                                    alt="device-icon" width={48}/>
                                                                    }
                                                                    <div style={{fontSize: 12}}>
                                                                        {
                                                                            check_kfire(this.state.device_data) ?
                                                                                this.state.modal_data["device_data"]["sys_info"]["firewall"]
                                                                                :
                                                                                check_server(this.state.modal_data["device_data"]) ? "Server" : "Workstation"
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    border: '1px #A1A1AA solid'
                                                                }}/>

                                                                <div style={{
                                                                    width: '500px',
                                                                    height: '100%',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        display: "flex",
                                                                        gap: 4,
                                                                        alignItems: "center",
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        <button
                                                                            style={{
                                                                                marginLeft: "auto",
                                                                                cursor: "pointer",
                                                                                border: "none",
                                                                                backgroundColor: "transparent",
                                                                                width: "16px",
                                                                                height: "16px"
                                                                            }}
                                                                            onClick={() => this.props.open_device(this.state.modal_data["device_data"]["key"])}
                                                                            id="device_btn"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    flex: "1",
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    height: "16px",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        position: "relative",
                                                                                        width: "16px",
                                                                                        height: "16px",
                                                                                    }}
                                                                                    alt=""
                                                                                    src="/main/show-i.svg"
                                                                                />
                                                                            </div>
                                                                        </button>
                                                                        {this.state.modal_data["device_data"]["host"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {this.state.modal_data["device_data"]["ip"]}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {this.getLastUser(this.state.modal_data["device_data"]["users"])}
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    border: '1px #A1A1AA solid'
                                                                }}/>

                                                                <div style={{
                                                                    width: '900px',
                                                                    height: '100%',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {safe_index(this.state.modal_data["device_data"], "sys_info", "full_name")}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {""}
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        display: "flex",
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        OS Build&nbsp;
                                                                        {safe_index(this.state.modal_data["device_data"], "sys_info", "major")}
                                                                        .
                                                                        {safe_index(this.state.modal_data["device_data"], "sys_info", "minor")}
                                                                        .
                                                                        {safe_index(this.state.modal_data["device_data"], "sys_info", "patch")}
                                                                        {
                                                                            safe_index(this.state.modal_data["device_data"], "sys_info", "build") === "N/A" ? "" :
                                                                                <div>
                                                                                    .
                                                                                    {safe_index(this.state.modal_data["device_data"], "sys_info", "build")}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        fontSize: 12,
                                                                        display: "flex",
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {
                                                                            get_patch_severity_render(this.state.modal_data["device_data"], true)
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    border: '1px #A1A1AA solid'
                                                                }}/>

                                                                <div style={{
                                                                    width: '400px',
                                                                    height: '100%',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {this.getStatusRender(this.state.modal_data["device_data"], true)}
                                                                    </div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        Last Seen
                                                                    </div>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 12,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '400',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        {get_time_string(this.state.modal_data["device_data"]["last_seen"])}
                                                                    </div>
                                                                </div>

                                                                <div style={{
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    border: '1px #A1A1AA solid'
                                                                }}/>

                                                                <div style={{
                                                                    width: '400px',
                                                                    height: '100%',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        wordWrap: 'break-word'
                                                                    }}>
                                                                        Authority
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        gap: 8,
                                                                        alignItems: "center"
                                                                    }}>
                                                                        {
                                                                            this.get_auth_to_act(this.state.modal_data["device_data"]) ?
                                                                                (<img src="/main/double-check-i.svg"
                                                                                      alt="flag"/>)
                                                                                :
                                                                                (<img src="/main/double-cross-i.svg"
                                                                                      alt="flag"/>)
                                                                        }
                                                                        <div style={{
                                                                            color: 'white',
                                                                            fontSize: 16,
                                                                            fontFamily: 'Inter',
                                                                            wordWrap: 'break-word'
                                                                        }}>
                                                                            Act
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        gap: 8,
                                                                        alignItems: "center"
                                                                    }}>
                                                                        {
                                                                            this.get_auth_to_isolate(this.state.modal_data["device_data"]) ?
                                                                                (<img src="/main/double-check-i.svg"
                                                                                      alt="flag"/>)
                                                                                :
                                                                                (<img src="/main/double-cross-i.svg"
                                                                                      alt="flag"/>)
                                                                        }
                                                                        <div style={{
                                                                            color: 'white',
                                                                            fontSize: 16,
                                                                            fontFamily: 'Inter',
                                                                            wordWrap: 'break-word'
                                                                        }}>
                                                                            Isolate
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    width: '1px',
                                                                    height: '100%',
                                                                    border: '1px #A1A1AA solid'
                                                                }}/>

                                                                <div style={{
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: 8,
                                                                    display: 'inline-flex',
                                                                    cursor: 'pointer'
                                                                }}
                                                                     onClick={() => this.setState({
                                                                         showHIC: true,
                                                                         hic_state: !this.state.modal_data["device_data"]["isolated"],
                                                                         hic_data: this.state.modal_data["device_data"]
                                                                     })}
                                                                >
                                                                    <div style={{
                                                                        height: 32,
                                                                        padding: 8,
                                                                        background: '#18181B',
                                                                        borderRadius: 2,
                                                                        border: '1px #F43F5E solid',
                                                                        justifyContent: 'center',
                                                                        width: 120,
                                                                        alignItems: 'center',
                                                                        gap: 4,
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            color: '#F43F5E',
                                                                            fontSize: 12,
                                                                            fontFamily: 'Inter',
                                                                            fontWeight: '500',
                                                                        }}
                                                                        >Isolation Control
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                            </div>

                                            {
                                                this.state.modal_data["device_data"] ?
                                                    check_kfire(this.state.modal_data["device_data"]) ?
                                                        <div style={{
                                                            width: 97,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            background: 'rgba(244, 63, 94, 0.25)',
                                                            borderRadius: 16,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#F43F5E',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>ShadowFire
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{
                                                            width: 112,
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            background: 'rgba(6, 182, 212, 0.25)',
                                                            borderRadius: 16,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 4,
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#22D3EE',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                wordWrap: 'break-word'
                                                            }}>
                                                                ShadowAgent
                                                            </div>
                                                        </div>
                                                    : ""
                                            }

                                            {/*new*/}
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "calc(50% - 8px)",
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    color: "white",
                                                }}
                                                >

                                                    <div style={{
                                                        background: "#3F3F46",
                                                        width: "100%",
                                                        borderTopRightRadius: 8,
                                                        borderTopLeftRadius: 8,
                                                        padding: 8,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                    >
                                                        <IAttackGroup
                                                            style={{
                                                                color: "#65A30D"
                                                            }}
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Attack Group
                                                        </div>
                                                    </div>

                                                    <div className="inner-tip-box"
                                                         style={{
                                                             borderTopRightRadius: 0,
                                                             borderTopLeftRadius: 0,
                                                             height: 100,
                                                         }}
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginTop: "4px",
                                                            height: "calc(100%-24px)"
                                                        }}
                                                             id="group-data">
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                color: "#A1A1AA",
                                                                gap: "4px",
                                                                width: "108px"
                                                            }} id="section1">
                                                                <div>IoA:</div>
                                                                <div>Threat Type:</div>
                                                                <div>Country:</div>
                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "4px"
                                                            }} id="section2">
                                                                <div>{this.state.modal_data["ioa"]}</div>
                                                                <div>{this.state.modal_data["group"]}</div>
                                                                {this.getCountryRender()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        marginTop: 16,
                                                        background: "#3F3F46",
                                                        width: "100%",
                                                        borderTopRightRadius: 8,
                                                        borderTopLeftRadius: 8,
                                                        padding: 8,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                    >
                                                        <IMalware
                                                            style={{
                                                                color: "#65A30D"
                                                            }}
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Malware
                                                        </div>
                                                        <button
                                                            style={{
                                                                marginLeft: "auto",
                                                                cursor: "pointer",
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                height: "24px"
                                                            }}
                                                            onClick={() => this.props.open_threat_card(this.state.modal_data["name"])}
                                                            id="threat_card_btn"
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: "1",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    height: "24px",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        position: "relative",
                                                                        width: "24px",
                                                                        height: "24px",
                                                                    }}
                                                                    alt=""
                                                                    src="/main/show-i.svg"
                                                                />
                                                            </div>
                                                        </button>
                                                    </div>

                                                    <div className="inner-tip-box"
                                                         style={{
                                                             borderTopRightRadius: 0,
                                                             borderTopLeftRadius: 0,
                                                             height: 100,
                                                         }}
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginTop: "4px",
                                                            height: "calc(100%-24px)"
                                                        }}
                                                             id="malware-data"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                color: "#A1A1AA",
                                                                gap: "4px",
                                                                width: "108px"
                                                            }} id="section1">
                                                                <div>Name:</div>
                                                                <div>Confidence:</div>
                                                                <div>Severity:</div>
                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "4px"
                                                            }} id="section2">
                                                                <div>{this.state.modal_data["name"]}</div>
                                                                <div>{this.state.modal_data["confidence"]}</div>
                                                                <div>{this.state.modal_data["severity"]}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div style={{
                                                    width: "calc(50% - 8px)",
                                                    alignItems: "stretch",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    color: "white"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#3F3F46",
                                                        width: "100%",
                                                        borderTopRightRadius: 8,
                                                        borderTopLeftRadius: 8,
                                                        padding: 8,
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "40px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                marginLeft: "auto",
                                                                width: 120,
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>
                                                    <div className="inner-tip-box" style={{
                                                        borderTopRightRadius: 0,
                                                        borderTopLeftRadius: 0,
                                                        flex: "1 1 auto",
                                                        gap: "16px"
                                                    }}
                                                    >
                                                        <div className="scroll-bar-div" style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: "0px 0px 0px 8px",
                                                            gap: "8px",
                                                            overflowY: "scroll",
                                                            overflowX: "hidden",
                                                            width: "100%",
                                                            height: "calc(100% - 16px)"
                                                        }}
                                                        >

                                                            {this.state.show_log_input ?
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginTop: "16px",
                                                                        width: "100%",
                                                                        height: "230px",
                                                                        marginRight: "8px",
                                                                    }}
                                                                >
                                                                    <MDEditor
                                                                        value={this.state.log_input_text}
                                                                        visibleDragbar={false}
                                                                        hideToolbar={true}
                                                                        preview="edit"
                                                                        extraCommands={[]}
                                                                        onChange={(data) => {
                                                                            this.setState({log_input_text: data})
                                                                        }}
                                                                        height={200}
                                                                    />
                                                                    <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                        <button onClick={() => {
                                                                            this.update_detection("", this.state.log_input_text)
                                                                            this.setState({
                                                                                show_log_input: false,
                                                                                log_input_text: ""
                                                                            })
                                                                        }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    borderRadius: "2px",
                                                                                    background: "transparent",
                                                                                    border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                    marginRight: "8px"
                                                                                }}
                                                                        >
                                                                            <ISubmit style={{
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                            }}/>
                                                                            Submit
                                                                        </button>
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                show_log_input: false,
                                                                                log_input_text: ""
                                                                            })
                                                                        }}
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    borderRadius: "2px",
                                                                                    background: "transparent",
                                                                                    border: "solid 1px #06B6D4",
                                                                                    color: "#06B6D4",
                                                                                    marginRight: "8px"
                                                                                }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                            }

                                                            {
                                                                this.state.modal_data["log"] ?

                                                                    this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            marginRight: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                             id="history-log-entry"
                                                                        >
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <img src="/main/tiphistoryclock-i.svg"
                                                                                     alt="tip-clock"/>
                                                                                <div style={{color: "#737373"}}>
                                                                                    {get_time_string(item["time"])}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                marginLeft: "8px",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <div style={{
                                                                                    background: "#71717a",
                                                                                    alignSelf: "stretch",
                                                                                    flexShrink: "0",
                                                                                    width: "1px"
                                                                                }}/>
                                                                                <MDEditor.Markdown source={item["text"]}
                                                                                                   style={{
                                                                                                       padding: "16px 16px 16px 16px",
                                                                                                       color: "#A1A1AA",
                                                                                                       background: "#18181B",
                                                                                                       borderRadius: "8px",
                                                                                                       whiteSpace: 'pre-wrap',
                                                                                                       width: "100%",
                                                                                                       height: "100%",
                                                                                                       maxHeight: "100%",
                                                                                                       overflow: "auto"
                                                                                                   }}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                    :
                                                                    ""
                                                            }

                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginRight: "8px",
                                                                gap: "8px"
                                                            }}
                                                                 id="history-log-entry"
                                                            >
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <img src="/main/tiphistoryclock-i.svg"
                                                                         alt="tip-clock"/>
                                                                    <div style={{color: "#737373"}}>
                                                                        {get_time_string(this.state.modal_data["created"])}
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    marginLeft: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        background: "#71717a",
                                                                        alignSelf: "stretch",
                                                                        flexShrink: "0",
                                                                        width: "1px"
                                                                    }}/>
                                                                    <MDEditor.Markdown
                                                                        source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                        style={{
                                                                            padding: "16px 16px 16px 16px",
                                                                            color: "#A1A1AA",
                                                                            background: "#18181B",
                                                                            borderRadius: "8px",
                                                                            whiteSpace: 'pre-wrap',
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            maxHeight: "100%",
                                                                            overflow: "auto"
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                            :
                            ""
                    }
                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showHIC}
                    contentLabel="Netflow"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 550,
                            height: 460,
                            display: "flex",
                            gap: "16px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            gap: 8,
                            fontWeight: "600",
                        }}
                        >
                            <img src="/main/mgt-isolated-i.svg" alt="isolated" width={28} height={27}/>
                            <div style={{color: "#F9B233"}}>
                                Host Isolation Confirmation
                            </div>
                        </div>


                        <div className="inner-tip-box"
                             style={{
                                 height: "380px"
                             }}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}>
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/tiphost.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Host Information
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: "4px",
                                height: "calc(100%-24px)"
                            }}
                                 id="host-data"
                            >

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    color: "#A1A1AA",
                                    gap: "4px",
                                    width: "100px"
                                }} id="section1">
                                    <div>Client IP:</div>
                                    <div>Hostname:</div>
                                    <div>Last User:</div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "4px"
                                }} id="section2">
                                    <div>{this.state.hic_data["ip"]}</div>
                                    <div>{this.state.hic_data["host"]}</div>
                                    <div>{this.getLastUser(this.state.hic_data["users"])}</div>
                                </div>
                            </div>


                            <div style={{
                                marginTop: 16,
                                marginBottom: 16,
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                                lineHeight: "28px",
                                fontWeight: "600",
                                alignItems: "center",
                                height: "24px",
                            }}>
                                <img
                                    style={{width: "24px", height: "24px"}}
                                    alt="host-icon"
                                    src="/main/hic-otp-i.svg"
                                />
                                <div style={{
                                    color: "#a1daf8"
                                }}>Enter OTP
                                </div>
                            </div>

                            <div style={{
                                width: '100%',
                                paddingLeft: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                marginBottom: 16,
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{width: 1, alignSelf: 'stretch', background: '#71717A'}}/>
                                <input className="transparent-input" type='number' name='supplier'
                                       autoFocus={true}
                                       style={{
                                           backgroundColor: "#18181B",
                                           padding: 16,
                                           borderRadius: 8,
                                           height: "52px"
                                       }}
                                       onChange={(data) => {
                                           this.setState({hic_otp: data.target.value})
                                       }}
                                />
                            </div>

                            <div style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}
                            >
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: this.state.hic_otp.length === 6 ? 'rgba(238,3,3,0.25)' : 'rgba(113, 113, 122, 0.25)',
                                    animation: this.state.hic_otp.length === 6 ? "light-pulse 1s alternate infinite" : "",
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex'
                                }}
                                        onClick={() => {
                                            if (this.state.hic_otp.length === 6) {
                                                this.isolation_route()
                                            }
                                        }
                                        }
                                >
                                    <div style={{
                                        color: this.state.hic_otp.length === 6 ? 'white' : '#71717A',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>
                                        {
                                            this.state.hic_state ?
                                                "Isolate " : "Release "
                                        }
                                        Host
                                    </div>
                                </button>
                                <button style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    flex: '1 1 0',
                                    height: 32,
                                    padding: 8,
                                    background: 'rgba(6, 182, 212, 0.25)',
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 4,
                                    display: 'flex',
                                }}
                                        onClick={this.close_hic}
                                >
                                    <div style={{
                                        color: '#06B6D4',
                                        fontSize: 12,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word'
                                    }}>Cancel
                                    </div>
                                </button>
                            </div>

                            <div hidden={!this.state.invalid_otp}
                                 style={{
                                     marginTop: 8,
                                     gap: 4,
                                     display: "flex",
                                     alignItems: "center"
                                 }}
                            >
                                <img alt="" src="/main/icon-l5-red.svg"/>
                                <div style={{color: "#EF4444"}}>Invalid OTP</div>
                            </div>

                            <div style={{
                                width: '100%',
                                marginTop: "auto",
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: 8,
                                display: 'inline-flex'
                            }}>
                                <div style={{
                                    width: 24,
                                    height: 24,
                                    padding: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                    <img src="/main/hic-note-i.svg" alt="note"/>
                                </div>
                                <div style={{
                                    flex: '1 1 0',
                                    color: '#A1A1AA',
                                    fontSize: 14,
                                    fontFamily: 'Inter',
                                    fontWeight: '400',
                                    letterSpacing: 0.07,
                                    wordWrap: 'break-word'
                                }}>
                                    {
                                        this.state.hic_state ?
                                            "Isolating a host will stop all communication on that host for all adapters. The only " +
                                            "communications allowed are via the CyberShadows platform."
                                            :
                                            "Releasing a host will enable normal communication on that host for all adapters."
                                    }
                                </div>
                            </div>

                        </div>

                    </div>


                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPlaybook}
                    contentLabel="Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                            gap: "8px"
                        }}
                        >
                            <img
                                alt="playbook"
                                src="/main/playbook-i.svg"
                            />
                            <div style={{color: "#F9B233"}}>
                                {this.state.playbook.title}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPlaybook: false})}
                            >
                                <img
                                    alt="close-playbook"
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div className="inner-tip-box" style={{
                            height: "100%",
                            gap: "16px"
                        }}>
                        <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "520px"
                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/playbook-obj-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Objective
                                    </div>
                                </div>
                                <div>
                                    {this.state.playbook.objective}
                                </div>


                                {
                                    this.state.playbook["steps"] ?
                                        this.state.playbook["steps"].map((item, i) =>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src={"/main/playbook-" + (i + 1).toString() + "-i.svg"}
                                                         alt="playbook-num"/>
                                                    <div style={{color: "#a1daf8"}}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown source={item.text}
                                                                       style={{
                                                                           padding: "16px 8px 16px 8px",
                                                                           color: "#A1A1AA",
                                                                           background: "#18181B",
                                                                           borderRadius: "8px",
                                                                           width: "100%",
                                                                           height: "100%",
                                                                           maxHeight: "100%",
                                                                           overflow: "auto"
                                                                       }}
                                                    />
                                                </div>

                                            </div>
                                        )
                                        :
                                        ""
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: "8px",
                                    gap: "8px"
                                }}
                                >
                                    <div style={{alignSelf: "stretch", flexShrink: "0"}}>
                                        <img src="/main/playbook-note-i.svg" alt="playbook-note"/>
                                    </div>
                                    <div style={{color: "#A1A1AA"}}>
                                        {this.state.playbook.note}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </ReactModal>

                <div style={{
                    width: "100%",
                    height: "1px",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        P1 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P1")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(239,68,68)",
                                     animation: this.state.d[0] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >


                                <CircularProgressbarWithChildren value={this.state.p[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     pathTransitionDuration: 0.5,
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(239,68,68,0.35)'
                                                                 })}
                                >
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[0]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(239,68,68,100)'
                                                                         })}>

                                            <div style={{marginBottom: -20}}>{this.state.d[0]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(239,68,68,0.35)'
                                            }}>{this.state.p_val[0]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(220, 38, 38, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#ef4444"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        CRITICAL
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P2 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P2")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(249,115,22)",
                                     animation: this.state.d[1] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(249,115,22,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[1]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#F97316'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[1]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(249,115,22,0.35)'
                                            }}>{this.state.p_val[1]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(234, 88, 12, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#f97316",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            HIGH
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P3 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P3")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(234,179,8)",
                                     animation: this.state.d[2] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[2]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(234,179,8,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[2]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#EAB308'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[2]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(234,179,8,0.35)'
                                            }}>{this.state.p_val[2]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(202, 138, 4, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "0px 8px",
                                            color: "#eab308",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            MEDIUM
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P4 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P4")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(6,182,212)",
                                     animation: this.state.d[3] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[3]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(6,182,212,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[3]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#06B6D4'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[3]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(6,182,212,0.35)'
                                            }}>{this.state.p_val[3]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(8, 145, 178, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#06b6d4",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            LOW
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        False Positive Rate
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("False Positive Rate")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.tp_rate} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#8B5CF6'
                                                                 })}>

                                    <div>{this.state.tp_rate}%</div>
                                </CircularProgressbarWithChildren>
                            </div>

                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "calc(100% - 8px)",
                            borderRadius: "8px",
                            height: "100%"
                        }}>

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "16px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <div style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    borderRadius: "4px 4px 0 0",
                                    alignItems: "center",
                                    backgroundColor: "#27272A",
                                    color: "#F9B233",
                                    padding: "4px 16px",
                                    gap: "4px",
                                    marginBottom: "-10px"
                                }}>
                                    <IDetections style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#65A30D",
                                    }}/>
                                    Detections
                                </div>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="4"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 4 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 4 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            All
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="0"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 0 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 0 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            New
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="1"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 1 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 1 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Pending
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="2"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 2 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 2 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            False Positive
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="3"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 3 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 3 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Closed
                                        </div>
                                    </div>
                                </button>


                            </div>
                            <ThemeProvider theme={this.tableTheme}>
                                <MaterialReactTable
                                    enableRowActions={true}
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: 'Tipper'
                                        },
                                    }}
                                    renderRowActions={({row}) => (
                                        <button
                                            className="menu-entry"
                                            onClick={() => this.OpenModal(row["original"])}
                                            style={{
                                                width: "100px"
                                            }}
                                            id="show-btn"
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    borderRadius: "2px",
                                                    padding: "7px",
                                                    backgroundColor: "#27272A"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontSize: "14px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    View
                                                </div>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                    alt=""
                                                    src="/main/show-i.svg"
                                                />
                                            </div>
                                        </button>
                                    )}
                                    columns={columns} data={this.getTableData()}
                                    initialState={{
                                        sorting: [{
                                            id: 'updated',
                                            desc: true,
                                        }]
                                    }}
                                >
                                </MaterialReactTable>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}