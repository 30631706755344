import React, { Component } from 'react'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';


export default class ShadowGRC extends Component {

    constructor(props) {
        super(props);
        this.state = {username: '', company: '', key: '', keys: {}};
        let data = this.props.page_data()
        console.log("ShadowGRC", data)
        this.setState({username: data.username, key: data.keys[data.company], company: data.company, keys: data.keys})
    }

    componentDidMount() {
        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
    }
    componentWillUnmount() {

    }


    render() {
        return (
            <div
                className="cp-scroll-bar-div"
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >
                <div
                    style={{
                        backgroundImage: "url(/main/ShadowGRC3.png)",
                        height: "1330px",
                        backgroundPositionY: "top",
                        backgroundPositionX: "left",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        width: "100%",
                        zIndex: 100,
                        overflow: "auto"
                    }}
                >
                </div>
            </div>
        )
    }
}